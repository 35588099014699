import VueRouter from 'vue-router';
import store from "./store/index";

const LandingPage = () =>
    import( /* webpackChunkName: "landing-route" */ './components/LandingPage.vue');

const Register = () =>
    import( /* webpackChunkName: "auth-route" */ './components/auth/Register.vue');

const Login = () =>
    import( /* webpackChunkName: "auth-route" */ './components/auth/Login.vue');

const GuestResetPassword = () =>
    import( /* webpackChunkName: "auth-route" */ './components/auth/GuestResetPassword.vue');

//logged page
const LoggedPage = () =>
    import( /* webpackChunkName: "logged-route" */ './components/logged/LoggedPage.vue');

const Dashboard = () =>
    import( /* webpackChunkName: "logged_route" */ './components/logged/main/Dashboard.vue');
const AkunSaya = () =>
    import( /* webpackChunkName: "logged-route" */ './components/logged/akun/AkunSaya.vue')
const NotFound = () =>
    import( /* webpackChunkName: "logged-route" */ './components/logged/NotFound.vue');

//superadmin route
const Users = () =>
    import( /* webpackChunkName: "superadmin-route" */ './components/logged/main/Users.vue');

//master route 
const Roles = () =>
    import( /* webpackChunkName: "master-route" */ './components/logged/main/master/Roles.vue');
const Pembelajaran = () =>
    import( /* webpackChunkName: "master-route" */ './components/logged/main/master/pembelajaran/Pembelajaran.vue');
const Rekening = () =>
    import( /* webpackChunkName: "master-route" */ './components/logged/main/master/Rekening.vue');
const WaktuAbsen = () =>
    import( /* webpackChunkName: "master-route" */ './components/logged/main/master/WaktuAbsen.vue');
const TglConf = () =>
    import( /* webpackChunkName: "master-route" */ './components/logged/main/master/TglConf.vue');

//admin tutor route
const AbsenTutorManager = () =>
    import( /* webpackChunkName: "admin-tutor-route" */ './components/logged/main/admin_tutor/AbsenTutorManager.vue');
const AbsenOncallManager = () =>
    import( /* webpackChunkName: "admin-tutor-route" */ './components/logged/main/admin_tutor/AbsenOncallManager.vue');
const AdminPeminjamanSpeaker = () =>
    import( /* webpackChunkName: "admin-tutor-route" */ './components/logged/main/admin_tutor/peminjaman_speaker/AdminPeminjamanSpeaker.vue');

//akademik route 
const Grup = () =>
    import( /* webpackChunkName: "akademik-route" */ './components/logged/main/akademik/Grup.vue');
const Periode = () =>
    import( /* webpackChunkName: "akademik-route" */ './components/logged/main/akademik/periode/Periode.vue');
const GrupTutor = () =>
    import( /* webpackChunkName: "akademik-route" */ './components/logged/main/akademik/grup_tutor_geo/GrupTutorGeo.vue');

//tutor route
const Tutor = () =>
    import( /* webpackChunkName: "tutor-route" */ './components/logged/main/tutor/Tutor.vue');
const UnAktifTutor = () =>
    import( /* webpackChunkName: "tutor-route" */ './components/logged/main/tutor/UnAktifTutor.vue');

const TitikAbsen = () =>
    import( /* webpackChunkName: "titik-absen-route" */ './components/logged/main/geolokasi/TitikAbsen.vue');

const TutorEditor = () =>
    import( /* webpackChunkName: "tutor-route" */ './components/logged/main/tutor/TutorEditor.vue');

const ProfilGrup = () =>
    import( /* webpackChunkName: "tutor-route" */ './components/logged/main/tutor/ProfilGrup.vue');
const JumlahMahasiswa = () =>
    import( /* webpackChunkName: "tutor-route" */ './components/logged/main/tutor/profil_grup/JumlahMahasiswa.vue');
const PeminjamanSpeaker = () =>
    import( /* webpackChunkName: "tutor-route" */ './components/logged/main/tutor/PeminjamanSpeaker.vue');

//laporan
const LaporanAbsensi = () =>
    import( /* webpackChunkName: "laporan-route" */ './components/logged/main/laporan/LaporanAbsensi.vue');

const LaporanOnCall = () =>
    import( /* webpackChunkName: "laporan-route" */ './components/logged/main/laporan/LaporanPengganti.vue');

//config-page
const ConfigPage = () =>
    import( /* webpackChunkName: "config-page" */ './components/logged/main/dev/ConfigPage.vue');

//app-info
const AppInfo = () =>
    import( /* webpackChunkName: "app-info" */ './components/logged/AppInfo.vue');

const MyBackup = () =>
    import( /* webpackChunkName: "dev-route" */ './components/logged/dev/MyBackup.vue');

const InfoSingkat = () =>
    import( /* webpackChunkName: "info-singkat-route" */ './components/logged/main/info_singkat/InfoSingkat.vue');

const AUTH_ROUTES = [{
    path: "/register",
    name: "Register",
    meta: { guest: true },
    component: Register,
},
{
    path: "/reset-password",
    name: "GuestResetPassword",
    meta: { guest: true },
    component: GuestResetPassword,
}
];

const SUPERADMIN_ROUTE = [{
    path: "/app/users",
    name: "Users",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: Users
    }
}, {
    path: "/app/config",
    name: "ConfigPage",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: ConfigPage
    }
}, {
    path: "/app/backup",
    name: "MyBackup",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: MyBackup
    }
}, {
    path: "/app/info-singkat",
    name: "InfoSingkat",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: InfoSingkat
    }
},];

const AKADEMIK_ROUTE = [{
    path: "/app/grup",
    name: "Grup",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: Grup
    }
}, {
    path: "/app/periode",
    name: "Periode",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: Periode
    }
}, {
    path: "/app/grup-tutor",
    name: "GrupTutor",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: GrupTutor
    }
}];

const TITIK_ABSEN_ROUTE = [{
    path: "/app/titik-absen",
    name: "TitikAbsen",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: TitikAbsen
    }
},];

const ADMIN_TUTOR_ROUTE = [{
    path: "/app/absen-tutor-manager",
    name: "AbsenTutorManager",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: AbsenTutorManager
    }
}, {
    path: "/app/absen-oncall-manager",
    name: "AbsenOncallManager",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: AbsenOncallManager
    }
}, {
    path: "/app/admin-peminjaman-speaker",
    name: "AdminPeminjamanSpeaker",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: AdminPeminjamanSpeaker
    }
},];


const MASTER_ROUTE = [{
    path: "/app/roles",
    name: "Roles",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: Roles
    }
}, {
    path: "/app/pembelajaran",
    name: "Pembelajaran",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: Pembelajaran
    }
}, {
    path: "/app/rekening",
    name: "Rekening",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: Rekening
    }
}, {
    path: "/app/tanggal-config",
    name: "TglConf",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: TglConf
    }
}, {
    path: "/app/waktu-absen",
    name: "WaktuAbsen",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: WaktuAbsen
    }
}];

const TUTOR_ROUTE = [{
    path: "/app/tutor",
    name: "Tutor",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: Tutor
    }
}, {
    path: "/app/un-aktif-tutor",
    name: "UnAktifTutor",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: UnAktifTutor
    }
}, {
    path: "/app/tutor/create",
    name: "TutorEditor",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: TutorEditor
    }
}, {
    path: "/app/tutor/:s",
    name: "TutorSearch",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: Tutor
    }
}, {
    path: "/app/tutor/edit/:ckey",
    name: "EditTutor",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: TutorEditor
    }
}, {
    path: "/app/jumlah-mahasiswa",
    name: "JumlahMahasiswa",
    meta: { requiresAuth: true, },
    components: {
        default: LoggedPage,
        MainView: JumlahMahasiswa
    }
}, {
    path: "/app/peminjaman-speaker",
    name: "PeminjamanSpeaker",
    meta: { requiresAuth: true, },
    components: {
        default: LoggedPage,
        MainView: PeminjamanSpeaker
    }
}, {
    path: "/app/profil-grup",
    name: "ProfilGrup",
    meta: { requiresAuth: true, },
    components: {
        default: LoggedPage,
        MainView: ProfilGrup
    }
},];

const LAPORAN_ROUTE = [{
    path: "/app/laporan-absensi",
    name: "LaporanAbsensi",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: LaporanAbsensi
    }
}, {
    path: "/app/laporan-on-call",
    name: "LaporanOnCall",
    meta: { requiresAuth: true, adminOnly: true },
    components: {
        default: LoggedPage,
        MainView: LaporanOnCall
    }
},];

const LOGGED_ROUTES = [{
    path: "/app",
    component: LoggedPage,
    meta: { requiresAuth: true },
    children: [{
        path: '/app',
        redirect: '/app/dashboard'
    },
    {
        path: "/app/akun-saya",
        name: "AkunSaya",
        components: {
            default: LoggedPage,
            MainView: AkunSaya
        }
    },
    {
        path: "/app/dashboard",
        name: "Dashboard",
        components: {
            default: LoggedPage,
            MainView: Dashboard
        }
    },
    ...SUPERADMIN_ROUTE,
    ...AKADEMIK_ROUTE,
    ...TITIK_ABSEN_ROUTE,
    ...MASTER_ROUTE,
    ...TUTOR_ROUTE,
    ...ADMIN_TUTOR_ROUTE,
    ...LAPORAN_ROUTE,
    {
        path: "/app/app-info",
        name: "AppInfo",
        components: {
            default: LoggedPage,
            MainView: AppInfo
        }
    },
    {
        path: "/app/404",
        name: "NotFound",
        components: {
            default: LoggedPage,
            MainView: NotFound
        }
    },
    {
        path: '/app/*',
        redirect: '/app/404'
    }
    ]
}];

const routes = [{
    path: "/",
    name: "LandingPage",
    component: LandingPage,
    meta: { guest: true },
}, ...AUTH_ROUTES, ...LOGGED_ROUTES,
{
    path: '/*',
    redirect: '/'
}
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

function loggedIn() {
    return localStorage.getItem("pwewep34KKOE34epwep68_9dwjDKLL");
}

router.beforeEach((to, from, next) => {
    let user = store.state.auth.user;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!loggedIn()) {
            next({
                path: '/login',
                // query: { redirect: to.fullPath }
            })
        } else {
            if (!user) {
                store.dispatch("auth/getUser").then(() => {
                    user = store.state.auth.user;
                    if (to.matched.some(record => record.meta.adminOnly) && user.role.val == 77) {
                        router.push('/app/dashboard');
                    } else {
                        next();
                    }
                })
            } else {
                if (to.matched.some(record => record.meta.adminOnly) && user.role.val == 77) {
                    router.push('/app/dashboard');
                } else {
                    next();
                }
            }
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (loggedIn()) {
            store.dispatch("auth/getUser").then(() => {
                user = store.state.auth.user;
                if (user.role.val == 99) {
                    router.push('/app/users');
                } else {
                    router.push('/app/dashboard');
                }
            })
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;