export const SET_ITEMS = (state, payload) => {
    state.items = payload;
}

export const SET_EXPAND_ITEM = (state, payload) => {
    let objIndex = state.items.findIndex((obj => obj.id == payload.id));
    state.items[objIndex][payload.key] = payload[payload.key];
}

export const SET_SPEAKER = (state, payload) => {
    const index = state.items.findIndex(item => item.id === payload.id);
    if (index !== -1) {
        // Replace the old item with the payload
        state.items.splice(index, 1, payload);
    }
}
