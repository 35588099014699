import { log } from '@chenfengyuan/vue-qrcode';
import axios from 'axios'

export const get = ({ commit }) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .get("/api/v1/admin-peminjaman-speaker")
            .then((response) => {
                if (response.data) {
                    commit('SET_ITEMS', response.data)
                    commit("SET_OVERLAY", false, { root: true });
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch(() => {

            });
    })
}