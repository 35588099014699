export const SET_ITEMS = (state, payload) => {
    state.items = payload;
}

export const SET_EXPAND_ITEM = (state, payload) => {
    let objIndex = state.items.findIndex((obj => obj.id == payload.id));
    state.items[objIndex][payload.key] = payload[payload.key];
}

export const SET_LATEST_ONE = (state, payload) => {
    state.latestOne = payload;
}
