import Vue from 'vue';
import Vuex from "vuex";

Vue.use(Vuex);

import state from "./state";
// import * as getters from './getters';
import * as mutations from "./mutations";
import * as actions from "./actions";

// import product from "./modules/product";
// import cart from "./modules/cart";

import auth from "./modules/auth/index";
import user from "./modules/user/index";
import role from "./modules/master/role/index";
import tutor from "./modules/tutor/index";
import pembelajaran from "./modules/master/pembelajaran/index";
import rekening from "./modules/master/rekening/index";
import grup from "./modules/akademik/grup/index";
import periode from "./modules/akademik/periode/index";
import tisen from "./modules/geolokasi/titik_absen/index";
import timer from "./modules/timer/index";
import grito from "./modules/akademik/grup_periode_tutor_geo/index";
import waksen from "./modules/master/waktu_absen/index";
import general from "./modules/general/index";
import atm from "./modules/absen_tutor_manager/index";
import aom from "./modules/absen_oncall_manager/index";
import lapabsensi from "./modules/laporan/laporan_absensi/index";
import laponcall from "./modules/laporan/laporan_on_call/index";
import config from "./modules/config/index";
import info from "./modules/info_singkat/index";
import tglconf from "./modules/master/tgl_conf/index";
import dashboard_oncall from "./modules/dashboard_oncall/index";

//2024
import jamker from "./modules/pinjam_speaker/index";
import minker from "./modules/admin_speaker/index";

export default new Vuex.Store({
    state,
    // getters,
    mutations,
    actions,

    modules: {
        auth,
        user,
        role,
        tutor,
        pembelajaran,
        rekening,
        grup,
        periode,
        tisen,
        timer,
        grito,
        waksen,
        general,
        atm,
        aom,
        lapabsensi,
        laponcall,
        config,
        info,
        tglconf,
        dashboard_oncall,
        jamker,
        minker
    }
});