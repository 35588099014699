export const SET_ROLE = (state, role) => {
    state.role = role;
}

export const SET_USER = (state, user) => {
    state.user = user;
}

export const SET_JUMLAH_MAHASISWA = (state, payload) => {
    if (state.user) {
        state.user.tutor.grup_periode_geo_now.grup.jumlah_mahasiswa = payload;
    }
}

export const SET_RATA_RATA_PRE = (state, payload) => {
    if (state.user) {
        state.user.tutor.grup_periode_geo_now.grup.rata_rata_pre_test = payload;
    }
}

export const SET_RATA_RATA_POST = (state, payload) => {
    if (state.user) {
        state.user.tutor.grup_periode_geo_now.grup.rata_rata_post_test = payload;
    }
}