import axios from 'axios'

export const getLatestOne = ({ commit }) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios
            .get("/api/v1/latest-peminjaman-speaker")
            .then((response) => {
                if (response.data) {
                    commit('SET_LATEST_ONE', response.data)
                    resolve(true)
                } else {
                    commit('SET_LATEST_ONE', response.data)
                    resolve(false)
                }

                commit("SET_OVERLAY", false, { root: true });
            })
            .catch(() => {
                commit("SET_OVERLAY", false, { root: true });
            });
    })
}

export const ajukanPinjamSpeaker = ({ commit }, payload) => {
    commit("SET_OVERLAY", true, { root: true });
    return new Promise((resolve, reject) => {
        axios.post("/api/v1/ajukan-peminjaman-speaker", payload).then((response) => {
            if (response.data) {
                commit('SET_LATEST_ONE', response.data);
                let notif = {
                    msg: "Peminjaman Speaker Berhasil diajukan~",
                    type: "create"
                }
                commit("SET_NOTIF", notif, { root: true });
                resolve("ok")
            }

            commit("SET_OVERLAY", false, { root: true });
        }).catch((e) => {
            if (e.response.data.emsg) {
                let notif = {
                    msg: e.response.data.emsg,
                    type: "info"
                }
                commit("SET_NOTIF", notif, { root: true });
                reject(e.response.data.emsg);
            }
            commit("SET_OVERLAY", false, { root: true });
            reject('err');
        });
    })
}